import { Controller } from '@hotwired/stimulus';
import { render, h, Fragment } from 'preact';
import { default as Widget1 } from '../pdp/src/components/v1/Widget/Widget';
import { default as Widget2 } from '../pdp/src/components/v2/Widget/Widget';
import { default as Widget3 } from '../pdp/src/components/v3/Widget/Widget';
import { default as Widget4 } from '../pdp/src/components/v4/Widget/Widget';
import { parseCssVariables, toFormName, formVariableNames } from '../pdp/src/theme/variables';

const defaultCssVariables = parseCssVariables();
const variableNames = formVariableNames();

const setEnabled = (row, enabled) => {
  const input = row.querySelector('input');
  input.disabled = !enabled;
  row.style.display = enabled ? 'flex' : 'none';
};

export default class extends Controller {
  static targets = [
    'layoutVersion',
    'contaminantBorderColor',
    'contaminantBoxColor',
    'contaminantUnitTextColor',
    'infoTextColor',
    'ingredientActualColor',
    'ingredientActualInsufficientColor',
    'ingredientClaimColor',
    'ingredientClaimInsufficientColor',
    'lightTextColor',
    'primaryTextColor',
    'questionIconColor',
    'widgetBackgroundColor',
    'widgetBorderColor',
    'accentColor',
    'activeBackgroundColor',
    'contaminantPassedBackgroundColor',
    'largeTextSize',
    'primaryTextSize',
    ...variableNames,
  ];

  connect() {
    this.rerender();
  }

  rerender() {
    const version = this.layoutVersionTarget.value;
    const versionClass = `v${version}`;
    const usesStyleOverrides = parseInt(version) > 3;

    // Explicitly disable hidden inputs so they aren't submitted
    const editForm = document.getElementById('edit-form');
    editForm.querySelectorAll('.style-override').forEach((row) => {
      const enabled = row.classList.contains(versionClass);
      const input = row.querySelector('input');
      input.disabled = !enabled;
      row.style.display = enabled ? 'flex' : 'none';
    });
    const noOverrideSection = editForm.querySelector('#no-override');
    noOverrideSection.style.display = usesStyleOverrides ? 'none' : 'flex';
    noOverrideSection.querySelectorAll('input').forEach((input) => (input.disabled = usesStyleOverrides));

    const Widget = this.widget(version);
    render(<Widget product={this.product()} style={this.style()} />, document.getElementById('pdp-container'));
  }

  widget(layoutVersion) {
    switch (layoutVersion) {
      case '4':
        return Widget4;
      case '3':
        return Widget3;
      case '2':
        return Widget2;
      default:
        return Widget1;
    }
  }

  setDefaults() {
    if (this.layoutVersionTarget.value > 3) {
      const versionVariables = defaultCssVariables[`v${this.layoutVersionTarget.value}`];
      Object.keys(versionVariables).forEach((name) => {
        const targetName = `${toFormName(name)}Target`;
        this[targetName].value = versionVariables[name];
      });
    } else {
      this.infoTextColorTarget.value = '#4374f9';
      this.lightTextColorTarget.value = '#6E7A7E';
      this.primaryTextColorTarget.value = '#000000';
      this.questionIconColorTarget.value = '#4374F9';
      this.widgetBackgroundColorTarget.value = '#FFFFFF';
      this.widgetBorderColorTarget.value = '#DEDEDE';
      this.accentColorTarget.value = '#577CFF';
      this.largeTextSizeTarget.value = '12px';
      this.primaryTextSizeTarget.value = '10px';

      this.activeBackgroundColorTarget.value = '#F8FAFF';
      this.ingredientActualColorTarget.value = '#D4EEFF';
      this.ingredientActualInsufficientColorTarget.value = '#E8B0C1';
      this.ingredientClaimColorTarget.value = '#7FC1ED';
      this.ingredientClaimInsufficientColorTarget.value = '#C47C93';

      this.contaminantBorderColorTarget.value = '#88BCB3';
      this.contaminantBoxColorTarget.value = '#E9FBF5';
      this.contaminantUnitTextColorTarget.value = '#56AC64';
      this.contaminantPassedBackgroundColorTarget.value = '#ECF0FF';
    }

    this.rerender();
  }

  style() {
    if (this.layoutVersionTarget.value > 3) {
      const versionVariables = defaultCssVariables[`v${this.layoutVersionTarget.value}`];
      return Object.keys(versionVariables).reduce((acc, name) => {
        const targetName = `${toFormName(name)}Target`;
        return {
          ...acc,
          [name]: this[targetName].value,
        };
      }, {});
    } else {
      return {
        '--ll-contaminant-border-color': this.contaminantBorderColorTarget.value,
        '--ll-contaminant-box-color': this.contaminantBoxColorTarget.value,
        '--ll-contaminant-unit-text-color': this.contaminantUnitTextColorTarget.value,
        '--ll-info-text-color': this.infoTextColorTarget.value,
        '--ll-ingredient-actual-color': this.ingredientActualColorTarget.value,
        '--ll-ingredient-actual-insufficient-color': this.ingredientActualInsufficientColorTarget.value,
        '--ll-ingredient-claim-color': this.ingredientClaimColorTarget.value,
        '--ll-ingredient-claim-insufficient-color': this.ingredientClaimInsufficientColorTarget.value,
        '--ll-light-text-color': this.lightTextColorTarget.value,
        '--ll-primary-text-color': this.primaryTextColorTarget.value,
        '--ll-question-icon-color': this.questionIconColorTarget.value,
        '--ll-widget-background-color': this.widgetBackgroundColorTarget.value,
        '--ll-widget-border-color': this.widgetBorderColorTarget.value,
        '--ll-accent-color': this.accentColorTarget.value,
        '--ll-active-background-color': this.activeBackgroundColorTarget.value,
        '--ll-contaminant-passed-background-color': this.contaminantPassedBackgroundColorTarget.value,
        '--ll-large-text-size': this.largeTextSizeTarget.value,
        '--ll-primary-text-size': this.primaryTextSizeTarget.value,
      };
    }
  }

  product() {
    return {
      contaminants: [
        {
          name: 'Arsenic',
          value: '< 2ppb',
        },
        {
          name: 'Cadmium',
          value: '< 3 ppb',
        },
        {
          name: 'Mercury',
          value: '< 5 ppb',
        },
      ],
      bigBars: [
        {
          name: 'Protein',
          claim: 3.1,
          actual: 2.0,
          units: 'g',
        },
        {
          name: 'Sodium',
          claim: 1.0,
          actual: 2.0,
          units: 'g',
        },
      ],
      skus: [
        {
          id: 1,
          name: 'Beef Bone Broth',
          code: 'TEST',
          shopifyVariantId: 1,
          product: {
            id: 1,
            name: 'TEST',
          },
          contaminants: [
            {
              name: 'Arsenic',
              value: '< 2ppb',
            },
            {
              name: 'Mercury',
              value: '< 5 ppb',
            },
          ],
          bigBars: [
            {
              name: 'Protein',
              claim: 3.1,
              actual: 2.0,
              units: 'g',
            },
          ],
        },
        {
          id: 2,
          name: 'Chicken Bone Broth',
          code: 'TEST',
          shopifyVariantId: 2,
          product: {
            id: 1,
            name: 'Chicken Bone Broth',
          },
          contaminants: [
            {
              name: 'Cadmium',
              value: '< 3 ppb',
            },
            {
              name: 'Mercury',
              value: '< 5 ppb',
            },
          ],
          bigBars: [
            {
              name: 'Sodium',
              claim: 1.0,
              actual: 2.0,
              units: 'g',
            },
          ],
        },
      ],
      lastTestDate: '2024-07-19T13:20:00.000Z',
    };
  }
}
