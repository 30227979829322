import { Controller } from '@hotwired/stimulus';
import loader from '@monaco-editor/loader';
import schemas from '../schemas';

export default class extends Controller {
  static targets = ['editor', 'hiddenInput'];
  static values = {
    schema: String,
  };

  connect() {
    const jsonSchema = schemas[this.schemaValue];
    console.log(this.schemaValue, schemas, jsonSchema);

    loader.init().then((monaco) => {
      // Register the JSON schema in Monaco
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemas: [
          {
            uri: 'https://my-app.com/schema/user.json', // Dummy URI to identify the schema
            fileMatch: ['*'],
            schema: jsonSchema,
          },
        ],
      });

      // Initialize Monaco Editor
      this.editor = monaco.editor.create(this.editorTarget, {
        value: this.hiddenInputTarget.value || '{}',
        language: 'json',
        theme: 'hc',
        automaticLayout: true,
        autoIndent: true,
        formatOnPaste: true,
        formatOnType: true,
      });

      // Listen for editor changes and update the hidden input field
      this.editor.getModel().onDidChangeContent(() => {
        this.hiddenInputTarget.value = this.editor.getValue();
      });
    });
  }

  // Optional: Clean up Monaco editor instance on disconnect
  disconnect() {
    if (this.editor) {
      this.editor.dispose();
    }
  }
}
