// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

import { Turbo } from "@hotwired/turbo-rails";

import "./controllers";
import * as bootstrap from "bootstrap";
import "../assets/stylesheets/application.scss";
import "trix";
import "@rails/actiontext";
import posthog from 'posthog-js';

import LocalTime from "local-time";
LocalTime.start();

import embed from "vega-embed";
window.vegaEmbed = embed;
window.dispatchEvent(new Event("vega:load"));

// https://turbo.hotwired.dev/reference/events#turbo%3Aload
document.addEventListener("turbo:load", function() {
    const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
    const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new bootstrap.Dropdown(dropdownToggleEl));
});

// https://turbo.hotwired.dev/reference/events#turbo%3Abefore-cache
document.addEventListener("turbo:before-cache", function() {
    // Remove the offcanvas prior to caching
    const offcanvas = document.getElementById("offcanvas");
    if (offcanvas) {
        offcanvas.remove();
    }

    // Remove the modal prior to caching
    const modal = document.getElementById("modal");
    if (modal) {
        modal.remove();
    }
});

// https://turbo.hotwired.dev/handbook/streams#custom-actions
// https://turbo.hotwired.dev/reference/streams
// https://github.com/hotwired/turbo-rails/pull/367
Turbo.StreamActions.visit = function() {
    Turbo.visit(this.target);
};

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
    posthog.init('phc_Vf0fsPUBFXZnnT2YIf6eqnTafVOPC01TUcpVreO2qW4', {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only'
    });
}
